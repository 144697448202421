
.rights__container {
    width: 100%;
    background: #1A1A1A;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
}

.rights__content-container {
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;

    & > h3 {
        color: #fff;
        font-size: 11px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 1.1px;
        text-transform: uppercase;
        text-align: center;

        @include lg {
            font-size: 10px;
            margin-bottom: 15px;
        }

        @include sm {
            font-size: 8px;
        }
    }

    @include lg {
        width: 100%;
        flex-direction: column;
    }
}

.rights__info-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;


}

.rights__button {
    display: flex;
    align-items: center;
    justify-content: center;

}

.rights__button > h3 {
    color: #fff;
    font-size: 11px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 1.1px;
    text-transform: uppercase;
    text-align: center;

    &:hover {
        text-decoration: underline !important; 
    }

    & > span {
        color: #fff;
        font-size: 11px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 1.1px;
        text-transform: uppercase;
        text-align: center;


        @include lg {
            font-size: 10px;
        }

        @include sm {
            font-size: 8px;
        }

    } 

    @include lg {
        font-size: 10px;
    }

    @include sm {
        font-size: 8px;
    }
}