
.about__container {
    width: 100%;
    min-height: 100vh;
    background: #F8F9FA;
    background-size: cover;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    // padding: 5vh 0vw;

    @include lg {
        min-height: 80vh;
        padding: 0vh 0vw;
    }
}

.about__id-target {
    height: 0px;
    width: 0px;
    position: absolute;
    top: 0vh;
    left: 0px;

    @include md {
        top: -15vh;
    }

    @include sm {
        top: -20vh;
    }
}

.about__content-container {
    width: 100%;
    min-height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include lg {
        width: 100%;
        background: url('../../images/faetanis.webp') bottom no-repeat;
        background-size: cover;
        padding: 5rem 0rem;

        &::before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: rgba(0, 0, 0, 0.8); 
            z-index: 0;
        
        }
    }
    

}

.about__title-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    position: relative;

    & > hr {
        width: 100%;
        height: 0.1px;
        background-color: #c9c9c9;
        margin: 0rem 5rem;
        border: none;

        @include md {
            margin: 0rem 3rem;
        }

        @include sm {
            margin: 0rem;
            position: absolute;
            visibility: hidden;
        }
    }

    & > h1 {
        min-width: fit-content;
        color: #000;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        letter-spacing: 3.2px;
        text-transform: uppercase;
        font-size: 28px;
        text-align: center;


        @include lg {
            font-size: 22px;
            color: #fff;
        }
    
        @include md {
            font-size: 16px;
        }
    
        @include sm {
            font-size: 14px;
        }

    }
    
}

.about__subtitle-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 3rem;
    position: relative;
    width: 90%;

    @include md {
        margin-top: 5rem;
    }

    & > h1 {
        min-width: fit-content;
        color: #252525;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        letter-spacing: 3.2px;
        text-transform: uppercase;
        font-size: 12px;

        @include lg {
            font-size: 11px;
            color: #fff;
        }

        @include md {
            font-size: 10px;
        }
    }
    
}

.about__info {
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    z-index: 2;
    margin-top: 5rem;
    position: relative;

    @include lg {
        width: 100%;
    }
    
}

.about__history-container {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1;

    @include lg {
        width: 90%;
    }

    & > h3 {
        font-size: 12px;
        color: #4F5457;
        text-align: justify;
        font-weight: 400;
        margin-bottom: 2rem;

        @include xl {
            margin-bottom: 1.5rem;
        }

        @include lg {
            color: #fff;
            line-height: 25px;
            font-weight: 300;
        }

        @include md {
            font-size: 12px;
            line-height: 20px;
            margin-bottom: 1rem;
        }

        @include sm {
            font-size: 10px;
            line-height: 15px;
        }
    
    
    }

    & > button {
        // Container
        border: 2px solid #4F5457;
        padding: 1rem 3rem;
        background-color: transparent;
        cursor: pointer;
        outline: none;
        margin-top: 1rem;
        z-index: 1;
        backdrop-filter: blur(10px);

        // Text
        color: #000;
        text-align: justify;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 3.2px;

        @include lg {
            color: #fff;
            border: 2px solid #fff;
        }

        @include md {
            font-size: 12px;
            border: 1px solid #fff;
            padding: 1rem 2rem;
        }

        @include sm {
            font-size: 10px;
            padding: 1rem 1.5rem;
        }
    }

}

.about__image {
    width: 50%;
    min-height: 100%;
    background: url('../../images/faetanis.webp') center no-repeat;
    background-size: cover;
    z-index: 1;
    position: relative;
    margin-right: 3rem;
    overflow: hidden;
    border: 1px solid #E6E6E6;
    box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.25);
 

    @include lg {
        visibility: hidden;
        position: absolute;
    }

}

@keyframes gradient-animation_2 {
    0% {
      transform: translateX(-3000%);
    }
  
    100% {
      transform: translateX(100%);
    }
  }

.about__image:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(110deg, rgba(227, 227, 227, 0) 0%, rgba(227, 227, 227, 0) 40%, rgba(227, 227, 227, 0.5) 50%, rgba(227, 227, 227, 0) 60%, rgba(227, 227, 227, 0) 100%);
    animation: gradient-animation_2 8s linear infinite;
    z-index: 2;
  }