
.logo__container {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;

    /* Animations */
    transition-timing-function: ease-in;
    transition: all 0.75s;

    @include md {
        margin-top: 20vh * 0.15;
    }
}

.logo__animated-container {
}

.logo__logoImage {
     width: 38rem;

     @include xxl {

        @include sm-h {
            width: 24rem;
        }
    }

    @include lg {
        width: 32rem;

        @include sm-h {
            width: 24rem;
        }
    }

    @include md {
        width: 32rem;

        @include sm-h {
            width: 24rem;
        }
    }

    @include sm {
        width: 24rem;
    }

    @include xs {
        width: 24rem;
    }
}

.logo__logoName {
    color: white;
    font-family: $main_font;
    font-size: 3.4rem;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: 3.4px;
    text-transform: uppercase;
    margin-left: 15px;

    @include lg {
        font-size: 3rem;
    }

    @include sm {
        font-size: 2.5rem;
    }

    @include xs {
        font-size: 2rem;
    }
}

.logo__logoName > span {
    color: white;
    font-family: $main_font;
    font-size: 3.2rem;
    font-style: normal;
    font-weight: 200;
    line-height: normal;
    letter-spacing: 3.2px;

    @include lg {
        font-size: 2.9rem;
    }

    @include sm {
        font-size: 2.4rem;
    }

    @include xs {
        font-size: 1.9rem;
    }
}