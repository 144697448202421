
.services__container {
    width: 100%;
    min-height: 100vh;
    background: #FFFFFF;
    background-size: cover;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 5vh 0vw;

    @include xxl {

        @include sm-h {
            padding: 15vh 0vw;
        }
    }

    @include md {
        min-height: 80vh;
        padding: 0vh 0vw;

        @include sm-h {
            padding: 15vh 0vw;
        }
    }

}

.services__id-target {
    height: 0px;
    width: 0px;
    position: absolute;
    top: 0vh;
    left: 0px;

    @include md {
        top: -20vh;
    }
}

.services__content-container {
    width: 100%;
    min-height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.services__title-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    position: relative;

    & > hr {
        width: 100%;
        height: 0.1px;
        background-color: #c9c9c9;
        margin: 0rem 4rem;
        border: none;

        @include lg {
            margin: 0rem 3rem;
        }

        @include md {
            margin: 0rem 2rem;
        }

        @include sm {
            margin: 0rem;
            position: absolute;
            visibility: hidden;
        }
    }

    & > h1 {
        min-width: fit-content;
        color: #000;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        letter-spacing: 3.2px;
        text-transform: uppercase;
        font-size: 28px;
        text-align: center;


        @include lg {
            font-size: 22px;
        }
    
        @include md {
            font-size: 16px;
            min-width: 75%;
        }
    
        @include sm {
            font-size: 14px;
            width: 90%;
        }

    }
    
}

.services__subtitle-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 3rem;
    position: relative;

    @include md {
        margin-top: 5rem;
    }

    & > h1 {
        min-width: fit-content;
        color: #252525;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        letter-spacing: 3.2px;
        text-transform: uppercase;
        font-size: 12px;

        @include lg {
            font-size: 11px;
        }

        @include md {
            font-size: 10px;
        }
    
        @include sm {
            font-size: 10px;
        }
    }
    
}

.services__list-container {
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 3rem;


    @include md {
        display: unset;
        margin-top: 7rem;

    }

    &::-webkit-scrollbar {
        width: 0.01px;
        height: 0.01px;
        background-color: transparent;
    }
    
    &::-webkit-scrollbar-thumb {
        background-color: transparent;
        border-radius: 0.01px;
    }
}

.services__list {
    display: grid;
    column-gap: $space;
    row-gap: 3rem;
    grid-template-columns: repeat(4, 1fr);
    margin: 1rem 0rem;
    padding: 0rem 2rem;
    width: fit-content;

    @include lg {
        column-gap: $space / 1.5;
    }

    @include md {
        grid-template-columns: repeat(8, 1fr);
        column-gap: $space / 2;
        // animation: scroll 30s ease-in-out infinite;
        width: auto;
    }

    @include sm {
        column-gap: $space / 3;
    }
}


  @keyframes scroll {
    0%, 100% {
        transform: translateX(50px);
      }
      50% {
        transform: translateX(-80%);
      }
  }

.services__card {
    height: 22rem;
    width: 15rem;
    border-radius: 8px;
    border: 1px solid #E6E6E6;
    box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;
    
}

.first-content {
    height: 100%;
    width: 100%;
    transition: all 0.4s;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;
    border-radius: 15px;
    overflow: hidden;
  }
  
  .services__card:hover .first-content {
    height: 0px;
    opacity: 0;
  }

  .second-content {
    display: none;
    height: 0%;
    width: 100%;
    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    transition: all 0.4s;
    font-size: 0px;
    transform: rotate(90deg) scale(0);
  }
  
  .services__card:hover .second-content {
    opacity: 1;
    height: 100%;
    font-size: 1.8rem;
    transform: rotate(0deg);
  }
  

.first-content > h1 {
    color: white;
    font-style: normal;
    line-height: normal;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 3px;
    padding: 0rem 2rem;
    text-align: center;
    z-index: 1;
}

.first-content > h2 {
    position: absolute;
    top: 10px;
    color: white;
    font-size: 8px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 5px;
    text-transform: uppercase;
    z-index: 1;
}

.first-content > img {
    position: absolute;
    bottom: 10px;
    color: white;
    font-size: 8px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 5px;
    text-transform: uppercase;
    z-index: 1;
    right: 10px;
    width: 2rem;
    height: 2rem;

}

.second-content > h1 {
    color: white;
    font-style: normal;
    line-height: normal;
    text-transform: uppercase;
    font-size: 9px;
    font-weight: 400;
    letter-spacing: 3px;
    z-index: 1;
}

.second-content > h1 > li {
    margin-bottom: 20px;
    text-align: start;

    &:last-of-type {
        margin-bottom: 0px;
    }
}


.second-content > h2 {
    position: absolute;
    top: 10px;
    color: white;
    font-size: 8px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 5px;
    text-transform: uppercase;
    z-index: 1;
    text-align: center;
    padding: 0px 10px;
}

.second-content > img {
    z-index: 1;
    width: 80%;
    height: 80%;
    object-fit: contain;
}

.services__card::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.64); 
    border-radius: 8px;
    z-index: 0;
}

.services__card:hover::before {
    backdrop-filter: blur(5px);
    
}

.services__card:nth-child(1) {
    background: url('../../images/tejas.webp') center no-repeat;
    background-size: cover;
}

.services__card:nth-child(2) {
    background: url('../../images/aca-5.webp') center no-repeat;
    background-size: cover;
}

.services__card:nth-child(3) {
    background: url('../../images/pizarras-servicio.webp') center no-repeat;
    background-size: cover;
}

.services__card:nth-child(4) {
    background: url('../../images/maderas.webp') center no-repeat;
    background-size: cover;
}

.services__card:nth-child(5) {
    background: url('../../images/reparaciones-servicio.webp') center no-repeat;
    background-size: cover;
}

.services__card:nth-child(6) {
    background: url('../../images/zinguerias-servicio.webp') center no-repeat;
    background-size: cover;
}

.services__card:nth-child(7) {
    background: url('../../images/partners2.webp') center no-repeat;
    background-size: contain;
    background-color: #111;

    h2 {
        color: #fff;
        font-weight: 500;
    }

    &:hover::before {
        backdrop-filter: blur(5px);
        // background-color: rgba(255, 255, 255,0.7);
    }

    &::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.2); 
        border-radius: 8px;
        z-index: 0;
    }
}

.services__card:nth-child(8) {
    background: url('../../images/restauraciones.webp') center no-repeat;
    background-size: cover;
}


// SCROLL ARROW


.services__scroll-container {
    position: absolute;
    right: 0px;
    left: 0px;
    bottom: -3.5rem;
    height: fit-content;
    width: 50px;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    visibility: hidden;

    @include md {
        visibility: visible;
    }

}


.services__scroll-arrow-go-left {
    position: relative;

    // @include sm {
    //     padding-left: 12.5px;
    // }
}

.services__scroll-arrow-go-right {
    position: relative;

    // @include sm {
    //     padding-left: 12.5px;
    // }
}


@keyframes animateGoLeft {
    0% {
        opacity: 0;
        transform: rotate(-225deg) translate(-10px, -10px);
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        transform: rotate(-225deg) translate(10px, 10px);
    }
}

@keyframes animateGoRight {
    0% {
        opacity: 0;
        transform: rotate(-45deg) translate(-10px, -10px);
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        transform: rotate(-45deg) translate(10px, 10px);
    }
}

.services__scroll-arrow-go-left span {
    display: block;
    width: 0.8rem;
    height: 0.8rem;
    border-bottom: 1px solid black;
    border-right: 1px solid black;
    transform: rotate(90deg);
    animation: animateGoLeft 3s infinite;

    @include sm {
        width: 0.5rem;
        height: 0.5rem;
    }
}

.services__scroll-arrow-go-right span {
    display: block;
    width: 0.8rem;
    height: 0.8rem;
    border-bottom: 1px solid black;
    border-right: 1px solid black;
    transform: rotate(90deg);
    animation: animateGoRight 3s infinite;

    @include sm {
        width: 0.5rem;
        height: 0.5rem;
    }
}
