
// Colors

$primary: #27ae60;
$dark-grey: #363636;
$light-grey: #f1eeee;

$white: #fff;

// Fonts

$main_font: 'Montserrat', sans-serif;

// Tamaño de Media Queries

$littlePhone: 400px;
$phone: 750px;
$tablet: 1200px;
$notebook: 1350px;
$desktop: 1024px;

$xs: 400px; // for small screen mobile
$sm: 600px; // for mobile screen
$md: 900px; // for tablets
$lg: 1280px; // for laptops
$xl: 1440px; // for desktop / monitors
$xxl: 1920px; // for big screens

// Colors

$primary: #006BC8;
$primary-light: #0090FF;
$primary-dark: #2C3357;
$dark: #000E19;
$grey: #918A8D;
$grey-dark: #535353;
$grey-normal: #E7E6E7;
$grey-light: #FAFAFA;
$border: #B7BABC;

$white: #fff;
$google-blue: #4285f4;
$button-active-blue: #1669f2;

$azul: #0da6f3;
$negro: #1a1b15;
$blanco: #FFFFFF;
$gris: #e1e1e1;

$space: 7.5rem;
