
@keyframes lineVertical {
    0% {
        height: 0vh;
    }
    100% {
        height: 15vh;
    }
}

@keyframes lineHorizontal {
    0% {
        width: 0vw;
    }
    100% {
        width: 15vw;
    }
}


@keyframes hide {
    0% {
        opacity: 1;
    }
    25% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes pulseLogo {
    0% {
        transform: scale(100%);
    }
    25% {
        transform: scale(110%);
    }
    50% {
        transform: scale(95%);
    }
    75% {
        transform: scale(105%);
    }
    100% {
        transform: scale(100%);
    }
}

@keyframes typewriter {
    0% {
      width: 0px;
    }
  
    100% {
        @include lg {
            width: 320px;
        }
        @include md {
            width: 320px;
        }
        @include sm {
            width: 280px;
        }
        @include xs {
            width: 240px;
        }
    }
  }
  
  @keyframes blink {
    0% {
      border-right-color: rgba(255,255,255,.75);
    }

    100% {
      border-right-color: transparent;
    }
  }

.preloader__container {
    width: 100vw;
    min-height: 100vh;
    background-color: #121212;
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 6;
    backdrop-filter: blur(5px);

    &::-webkit-scrollbar {
        width: 0.01px;
        height: 0.01px;
        background-color: transparent;
    }
    
    &::-webkit-scrollbar-thumb {
        background-color: transparent;
        border-radius: 0.01px;
    }

    & > h1 {
        color: #fff;

        & > span {
            color: #fff;
        }
    }

}

.preloader__content-container {
    position: relative;
    overflow: hidden;
    border-right: 3px solid;
    width: 350px;
    animation: typewriter 1s steps(20) normal , blink 0.5s steps(20) infinite;

    @include lg {
        width: 320px;
    }
    @include md {
        width: 320px;
    }
    @include sm {
        width: 280px;
    }
    @include xs {
        width: 240px;
    }
    
  }

.preloader__container.active {
    animation: hide 1s ease 2s;
}

.preloader__line-top {
    position: absolute;
    top: 50px;
    left: 50px;

    span:first-of-type:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 15vw;
        height: 1px;
        background-color: white;
    }

    span:last-of-type:before {
        content: '';
        position: absolute;
        width: 1px;
        top: 0;
        left: 0;
        height: 15vh;
        background-color: white;
    }
}

.preloader__line-bottom {
    position: absolute;
    bottom: 50px;
    right: 50px;

    span:first-of-type:before {
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        width: 15vw;
        height: 1px;
        background-color: white;
    }

    span:last-of-type:before {
        content: '';
        position: absolute;
        right: 0;
        bottom: 0;
        width: 1px;
        height: 15vh;
        background-color: white;
    }
}

.preloader__line-top.active {

    span:first-of-type:before {
        animation: lineHorizontal 2s ease;
    }

    span:last-of-type:before {
        animation: lineVertical 2s ease;
    }

}

.preloader__line-bottom.active {

    span:last-of-type:before {
        animation: lineVertical 2s ease;
    }

    span:first-of-type:before {
        animation: lineHorizontal 2s ease;
    }
    
}