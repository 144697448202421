
.button__floatButton {
    border-radius: 50px;
    position: fixed;
    width: 55px;
    height: 55px;
    right: 3rem;
    bottom: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 5;
    background: rgba(0, 0, 0, 0.2);
    box-shadow: 0px 4px 8px 0px rgba(49, 49, 49, 0.25);
    backdrop-filter: blur(2px);

    & > img {
        width: 30px;
        padding-left: 2px;
    }

    @include xxl {

        @include sm-h {
            width: 45px;
            height: 45px;
            right: 2rem;
            bottom: 2rem;

            & > img {
                width: 25px;
            }
        }
    }

    @include md {

        @include sm-h {
            width: 45px;
            height: 45px;
            right: 2rem;
            bottom: 2rem;

            & > img {
                width: 25px;
            }
        }
    }

}
