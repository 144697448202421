
.proyect__container {
    width: 100%;
    height: 100vh;
    background: url('../../images/techo-intro.webp') center no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
}

.proyect__image {
    width: 100%;
    height: 100vh;
    position: absolute;
    background-size: cover !important;
    &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.4); 
        z-index: 0;
    }
}

@keyframes pulse {
    0% {
        opacity: 0;
        transform: translateY(10rem);
    }
    40% {
        opacity: 0;
        transform: translateY(10rem);
    }
    100% {
        opacity: 1;
        transform: translateY(0rem);
    }
}


.proyect__info {
    width: 70%;
    text-transform: uppercase;
    z-index: 2;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    position: relative;
    margin-top: 20vh;
    

    & > h1 {
        font-size: 60px;
        font-weight: 500;
        font-style: italic;
        letter-spacing: 5px;
        margin-bottom: 20px;

        @include xxl {
            font-size: 60px;

            @include md-h {
                font-size: 50px;
            }
            @include sm-h {
                font-size: 30px;
            }
            @include xs-h {
                font-size: 20px;
            }
        }
    
        @include md {
            font-size: 40px;

            @include sm-h {
                font-size: 18px;
                margin-bottom: 2rem;
            }
        }
    
        @include sm {
            font-size: 20px;
        }
    
        @include xs {
            font-size: 20px;
        }

    }

    & > h2 {
        position: absolute;
        left: 0rem;
        top: -15rem;
        font-size: 20px;
        font-weight: 400;
        margin-bottom: 3rem;

        @include xxl {

            @include sm-h {
                top: -5rem;
                font-size: 12px;
            }
        }

        @include lg {
            font-size: 18px;

            @include sm-h {
                top: -5rem;
                font-size: 12px;
            }
        }


        @include md {
            font-size: 16px;
            top: -10rem;

            @include sm-h {
                top: -3rem;
                font-size: 10px;
            }
        }
    
        @include sm {
            font-size: 14px;
            top: -7rem;
        }
    
        @include xs {
            font-size: 12px;
        }
    }

    & > h3 {
        font-size: 22px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 2rem;
        text-transform: capitalize;

        span {
            font-weight: 400;
        }

        @include xxl {
            @include sm-h {
                font-size: 12px;
            }
        }

        @include lg {
            font-size: 20px;

            @include sm-h {
                font-size: 12px;
            }
        }

        @include md {
            font-size: 16px;

            @include sm-h {
                font-size: 12px;
            }
        }
    
        @include sm {
            font-size: 14px;
        }
    
        @include xs {
            font-size: 12px;
        }
    }

    @include lg {
        width: 75%;
    }

    @include md {
        width: 80%;
    }

}

.proyect__info.active {

    & > h1 {
        animation: pulse 1.5s ease;
    }

    & > h2 {
        animation: pulse 1s ease;
    }

    & > h3 {
        animation: pulse 1.25s ease;
    }
}

.proyect__info-comeback {
    position: absolute;
    left: 0rem;
    top: -20rem;
    font-size: 20px;
    font-weight: 400;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 10px 15px;
    border-radius: 50px;
    background-color: rgba(0, 0, 0, 0.4);

    @include xxl {

        @include sm-h {
            top: -7rem;
        }
    }

    @include md {
        font-size: 18px;
        top: -15rem;

        @include sm-h {
            top: -5rem;
        }
    }

    @include sm {
        font-size: 16px;
        top: -10rem;
    }

    @include xs {
        font-size: 14px;
    }

    & > div {
        color: white;
        margin-bottom: 1px;
        width: fit-content;
        transform: scaleX(-1);
        font-size: large;
        cursor: pointer;
        margin-right: 7px;

            
        @include sm {
            font-size: 12px;
            margin-bottom: 3px;
        }
    }

    & > h2 {
        font-size: 14px;
        font-weight: 400;
        text-transform: uppercase;
        color: white;
        letter-spacing: 2px;
        cursor: pointer;

        @include xxl {

            @include sm-h {
                font-size: 12px;
            }
        }

        @include md {

            @include sm-h {
                font-size: 10px;
            }
        }
    
        @include sm {
            font-size: 10px;
        }
    


    }

}

.proyect__info-comeback.active {
    animation: pulse 0.75s ease;
}

.proyect__scroll-container {
    position: absolute;
    right: 3rem;
    top: 0px;
    bottom: 0px;
    height: fit-content;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;
    cursor: pointer;
    padding: 20px 15px 40px;
    border-radius: 50px;
    background-color: rgba(0, 0, 0, 0.4);

    & > h2 {
        color: white;
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 15px;
        text-transform: uppercase;
        display: block;
    }

    & > h2 > span {
        display: block;
        margin-bottom: 5px; /* Ajusta el espacio entre las letras según sea necesario */
      }

      @include sm {
        right: 2rem;

        & > h2 {
            color: white;
            text-align: center;
            font-size: 10px;
            font-style: normal;
            font-weight: 400;
            line-height: 15px;
            text-transform: uppercase;
            display: block;
        }
    }

    @include xs {
        right: 1rem;
    }
}


.proyect__scroll-container2 {
    position: absolute;
    right: 0px;
    left: 0px;
    bottom: 5vh;
    width: fit-content;
    height: fit-content;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;
    cursor: pointer;
    padding: 15px 15px 35px;
    border-radius: 50px;
    background-color: rgba(0, 0, 0, 0.4);

    @include sm {
        bottom: 10vh;

    }
}


.proyect__scroll-arrow {
    position: relative;
    margin-top: 30px;
    padding-left: 8.5px;

    @include sm {
        padding-left: 11px;
    }
}


@keyframes animate {
    0% {
        opacity: 0;
        transform: rotate(45deg) translate(-20px, -20px);
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        transform: rotate(45deg) translate(20px, 20px);
    }
}

.proyect__scroll-arrow span {
    display: block;
    width: 0.8rem;
    height: 0.8rem;
    border-bottom: 1px solid white;
    border-right: 1px solid white;
    transform: rotate(45deg);
    margin: -10px;
    animation: animate 2s infinite;

    @include sm {
        width: 0.5rem;
        height: 0.5rem;
    }
}

.proyect__scroll-arrow span:nth-child(2) {
    animation-delay: -0.2s;
}

.proyect__scroll-arrow span:nth-child(3) {
    animation-delay: -0.4s;
}
