
.aboutModal__container {
    width: 100vw;
    min-height: 100vh;
    background-color: rgba(0,0,0,0.8);
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 6;
    backdrop-filter: blur(5px);

    &::-webkit-scrollbar {
        width: 0.01px;
        height: 0.01px;
        background-color: transparent;
    }
    
    &::-webkit-scrollbar-thumb {
        background-color: transparent;
        border-radius: 0.01px;
    }
}

.aboutModal__content-container {
    height: 100%;
    box-shadow: 0px 3px 6px #00000026;
    display: flex;
    flex-direction: column;
    position: relative;
    display: grid;
    gap: 4rem;
    row-gap: 2rem;
    grid-template-columns: repeat(3, 1fr);
    overflow: auto;

    &::-webkit-scrollbar {
        width: 0.01px;
        height: 0.01px;
        background-color: transparent;
    }
    
    &::-webkit-scrollbar-thumb {
        background-color: transparent;
        border-radius: 0.01px;
    }

    @include lg {
        grid-template-columns: repeat(2, 1fr);
        padding: 5rem 0rem;
        gap: 5rem;
        row-gap: 5rem;
    } 

    @include md {
        width: 90%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: start;
        scroll-snap-type: x mandatory;
        padding: 5rem 5rem;

    } 

    @include sm {
        gap: 3rem;
    } 

}

@keyframes pulseAboutModal {
    0% {
        transform: scale(100%);
    }
    50% {
        transform: scale(95%);
    }
    100% {
        transform: scale(100%);
    }
}



.aboutModal__info-container {
    width: 280px;
    height: 290px;
    flex-shrink: 0;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0px 12px 12px 0px rgba(0, 0, 0, 0.50);
    padding: 3rem 4rem 1rem 4rem;
    overflow: hidden; 
    position: sticky;
    object-fit: cover;
    scroll-snap-align: center;
    transition: all 0.5s;

    @include md {
        width: 360px;
        height: 400px;
        padding: 5rem 5rem 3rem 5rem;
    } 

    @include sm {
        width: 230px;
        height: 470px;
        padding: 3rem 4rem 0rem 4rem;
    } 

    @include xs {
        width: 200px;
        height: 420px;
        padding: 3rem 3rem 0rem 3rem;
    } 
}

.aboutModal__info-container.active {
    animation: pulseAboutModal 5s ease-in-out infinite;

    @include md {
        animation: none;
    } 
}

.aboutModal__info-container.active:nth-child(1) { animation-delay: 0.8s; }
.aboutModal__info-container.active:nth-child(2) { animation-delay: 1.0s; }
.aboutModal__info-container.active:nth-child(3) { animation-delay: 0.6s; }
.aboutModal__info-container.active:nth-child(4) { animation-delay: 0.4s; }
.aboutModal__info-container.active:nth-child(5) { animation-delay: 0.2s; }
.aboutModal__info-container.active:nth-child(6) { animation-delay: 1.0s; }


.aboutModal__info-container > h1 {
    width: fit-content;
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 6.4px;
    border-bottom: #52beff 5px solid;
    padding-left: 5px;
    padding-bottom: 5px;
    margin-bottom: 1rem;
    position: relative;
    transition: all 0.5s ease-out;

    @include md {
        margin-bottom: 3rem;
    } 

    @include sm {   
        font-size: 14px;
        margin-bottom: 1rem;
    } 

}

.aboutModal__info-container > h2 {
    color: #000;
    text-align: justify;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    z-index: 4;
    position: relative;
    transition: all 0.5s ease-out;

    @include md {
        font-size: 12px;
        line-height: 25px;
    } 

    @include sm {
        font-size: 12px;
        line-height: 22px;
    } 

    @include xs {
        font-size: 11px;
        line-height: 20px;
    } 
}

.aboutModal__info-container::before {
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    background: #52beff;
    height: 32px;
    width: 32px;
    border-radius: 32px;
    transform: scale(1);
    transform-origin: 50% 50%;
    transition: transform 0.5s ease-out;
    overflow: hidden;
    
  }
  
  .aboutModal__info-container:hover:before {
    transform: scale(50);
  }
  
  .aboutModal__info-container:hover h1 {
    transition: all 0.3s ease-out;
    color: rgba(255, 255, 255, 0.8);
    border-bottom: #ffffff 5px solid;
  }
  
  .aboutModal__info-container:hover h2 {
    transition: all 0.3s ease-out;
    color: #fff;
  }

  .aboutModal__close-image {
    width: 20px;
    height: 20px;
    position: fixed;
    top: 3rem;
    right: 3rem;
    cursor: pointer;
    z-index: 2;
}

.aboutModal__go-corner {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 50px;
    height: 50px;
    overflow: hidden;
    top: 0;
    right: 0;
    background-color: #52beff;
    border-radius: 1px 4px 1px 50px;
  }

  .aboutModal__go-arrow {
    margin-top: -10px;
    margin-right: -10px;
    color: white;
    font-size: small;
  }

.aboutModal__works-counter {
    position: absolute;
    bottom: 20vh;
    left: 0;
    right: 0;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    visibility: hidden;

    & > div {
        width: 10px;
        height: 10px;
        background-color: rgba(255, 255, 255, 0.5);
        margin-left: 7.5px;
        margin-right: 7.5px;
        border-radius: 10rem;
        z-index: 1;
    }

    @include md {
        visibility: visible;
    } 

    @include sm {
        bottom: 5vh;
    } 

}