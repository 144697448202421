
.navbar__container {

    /* Animations */
    transition-timing-function: ease-in;
    transition: all 0.75s;


 
    
    @include sm {
        width: 95%;
    }

    @include xs {
        width: 98%;
    }
}

.navbar__animated-container {
    
}

.navbar__buttons {
    width: 600px;
    display: flex;
    color: white;
    align-items: center;
    justify-content: space-between;

    @include lg {
        width: auto;
    }

    @include md {
        width: auto;
    }

    @include sm {
        width: auto;
    }
}

.navbar__buttons > button {
    color: white;
    text-transform: uppercase;
    font-weight: normal;
    font-size: 1.2rem;
    letter-spacing: 1.4px;
    padding: 10px;
    margin: 0px 10px;
    border-top: solid 1px transparent;
    border-bottom: solid 1px transparent;
    cursor: pointer;
    
    &:hover {
        border-color: white;
    }

    @include xxl {

        @include sm-h {
            font-size: 1rem;
        }
    }

    @include md {
        
        @include sm-h {
            font-size: 1rem;
        }
    }


    @include sm {
        font-size: 1rem;
        margin: 0px;
    }

    @include xs {
        padding-left: 5px;
        padding-right: 5px;
    }

}