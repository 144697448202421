
.footer__container {
    width: 100%;
    min-height: 80vh;
    background: url('../../images/tejas-social.webp') center no-repeat;
    background-size: cover;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    position: relative;
}

.footer__container::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.64); 
}

.footer__content-container {
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin: 10rem 0rem;

    @include sm {
        width: 80%;
        flex-direction: column;
    }
}

.footer__buttons-container {
    width: 49%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;

    @include sm {
        width: 100%;
        align-items: center;
    }
}

.footer__button {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;

    @include sm {
        margin-bottom: 50px;
        align-items: center;
    }
}

.footer__button > img {
    height: 24px;
    width: 24px;
}

.footer__button > h3 {
    color: #fff;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1.4px;
    text-decoration-line: underline;

    @include md {
        font-size: 12px;
    }
    
    @include sm {
        font-size: 10px;
        text-align: center;
    }

}

.footer__button:nth-child(5) {
    margin-top: 3rem;

    @include sm {
        margin-top: 0rem;
    }
}

.footer__maps {
    width: 49%;
    height: 30rem;
    border: 1px solid #E2E2E2;
    box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.75);

    @include sm {
        width: 100%;
        align-items: center;
    }
}

.footer__label-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 3rem;

    &:last-of-type {
        margin-top: 5rem;

        @include sm {
            margin-top: 0rem;
        }
    }

    & > img {
        width: 5rem;
        height: 5rem;

        @include md {
            width: 4rem;
            height: 4rem;
        }
    }

    & > h3 {
        color: #fff;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 10px;
        padding-left: 2rem;

        @include lg {
            font-size: 16px;
        }

        @include md {
            font-size: 12px;
        }

        @include sm {
            font-size: 12px;
        }
    
    }

}