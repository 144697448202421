
.work__container {
    width: 100%;
    min-height: 100vh;
    background: #eee;
    background-size: cover;
    z-index: 1;
    display: flex;
    align-items: center;
    position: relative;
    padding: 5vh 0vw;
    overflow: hidden;
    @include md {
        min-height: 80vh;
        padding: 0vh 0vw;
    }

}

.work__id-target {
    height: 0px;
    width: 0px;
    position: absolute;
    top: -10vh;
    left: 0px;
  
    @include sm {
      top: -15vh;
    }
}

.work__content-container {
    width: 100%;
    min-height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;

}

.work__title-container {
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    position: relative;
    margin-top: 5rem;

    & > hr {
        width: 100%;
        height: 0.1px;
        background-color: #c9c9c9;
        margin: 0rem 4rem;
        border: none;

        @include xxl {
            margin: 0rem 3rem;
        }

        @include md {
            margin: 0rem 2rem;
        }

        @include sm {
            margin: 0rem;
            position: absolute;
            visibility: hidden;
        }
    }

    & > h1 {
        min-width: fit-content;
        color: #000;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        letter-spacing: 3.2px;
        text-transform: uppercase;
        font-size: 28px;
        text-align: center;


        @include xxl {
            font-size: 22px;
        }
    
        @include md {
            font-size: 16px;
            min-width: 75%;
        }
    
        @include sm {
            font-size: 14px;
            width: 90%;
        }

    }
    
}

.work__subtitle-container {
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-top: 5rem;

    & > h1 {
        min-width: fit-content;
        color: #000;
        font-style: normal;
        font-weight: 300;
        letter-spacing: 3.2px;
        text-align: justify;
        font-size: 14px;
        line-height: 5rem;

        @include xxl {

            @include md-h {
                font-size: 12px;
                line-height: 3rem;

            }
            @include sm-h {
                font-size: 12px;
                line-height: 3rem;

            }
        }
    
        @include md {
            font-size: 12px;
            line-height: 3rem;

            @include sm-h {
                font-size: 10px;
                line-height: 2rem;
            }
        }
    
        @include sm {
            line-height: 2rem;
        }

    }
    
}

.work__list-container {
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    display: unset;
    margin-top: 2rem;
    
    &::-webkit-scrollbar {
        width: 0.01px;
        height: 0.01px;
        background-color: transparent;
    }
    
    &::-webkit-scrollbar-thumb {
        background-color: transparent;
        border-radius: 0.01px;
    }
}



.work__list {
    width: fit-content;
    display: grid;
    row-gap: 3rem;
    column-gap: $space / 2;
    margin-top: 11rem;
    margin-bottom: 5rem;
    padding-bottom: 1rem;
    margin-left: 5%;
    margin-right: 5%;
    // animation: scroll 10s ease-in-out infinite;
    

    @include lg {
        // column-gap: $space / 1.5;
    }

    @include md {
        // grid-template-columns: repeat(8, 1fr);
        // animation: scroll 30s ease-in-out infinite;
        // width: auto;
        margin-top: 8rem;
    }

    @include sm {
        column-gap: $space / 3;
        margin-top: 7rem;
    }
}


  @keyframes scroll {
    0%, 100% {
        transform: translateX(50px);
      }
      50% {
        transform: translateX(-80%);

      }

  }

.work__card {
    height: 40rem;
    width: 50rem;
    border: 1px solid #E6E6E6;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    transition: all 0.4s;
    border: 3px solid #E1E1E1;

    &:hover  {
        &::before {
            opacity: 1;
        }
    }

    @include md {
        height: 30rem;
        width: 40rem;
    }

    @include sm {
        height: 20rem;
        width: 30rem;
    }

}

// SCROLL ARROW

.work__scroll-container {
    position: absolute;
    right: 0px;
    left: 0px;
    bottom: -5vh;
    height: fit-content;
    width: 50px;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}


.work__scroll-arrow-go-left {
    position: relative;

    // @include sm {
    //     padding-left: 12.5px;
    // }
}

.work__scroll-arrow-go-right {
    position: relative;

    // @include sm {
    //     padding-left: 12.5px;
    // }
}


@keyframes animateGoLeft {
    0% {
        opacity: 0;
        transform: rotate(-225deg) translate(-10px, -10px);
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        transform: rotate(-225deg) translate(10px, 10px);
    }
}

@keyframes animateGoRight {
    0% {
        opacity: 0;
        transform: rotate(-45deg) translate(-10px, -10px);
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        transform: rotate(-45deg) translate(10px, 10px);
    }
}

.work__scroll-arrow-go-left span {
    display: block;
    width: 0.8rem;
    height: 0.8rem;
    border-bottom: 1px solid black;
    border-right: 1px solid black;
    transform: rotate(90deg);
    animation: animateGoLeft 3s infinite;

    @include sm {
        width: 0.5rem;
        height: 0.5rem;
    }
}

.work__scroll-arrow-go-right span {
    display: block;
    width: 0.8rem;
    height: 0.8rem;
    border-bottom: 1px solid black;
    border-right: 1px solid black;
    transform: rotate(90deg);
    animation: animateGoRight 3s infinite;

    @include sm {
        width: 0.5rem;
        height: 0.5rem;
    }
}
