
.social__container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 150px;
    padding-bottom: 150px;
    position: relative;
}

.social__id-target {
    height: 0px;
    width: 0px;
    position: absolute;
    top: -12vh;
    left: 0px;

    @include md {
        top: -20vh;
    }
  
    @include sm {
      top: -20vh;
    }
}

.social__content-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding-top: 50px;
    padding-bottom: 50px;
    background: rgba(26, 26, 26, 0.75);
    backdrop-filter: blur(1.5px);


}

.social__content-container > h1 {
    color: #fff;
    font-size: 60px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 6.8px;
    margin-bottom: 15px;

    @include md {
        font-size: 60px;
    }

    @include sm {
        font-size: 40px;
    }
}

.social__content-container > h2 {
    color: #fff;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 1.1px;
    text-transform: uppercase;

    @include sm {
        font-size: 10px;
    }
}


.social__buttons-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 55px;
}

.social__button {
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 15px;
    padding-right: 15px;

    &:nth-child(1) {
        border-right: solid 1px #d9d9d9;

    }

    @include sm {
        width: 85px;
        padding-left: 20px;
        padding-right: 20px;
    }
}

.social__button > img {
    height: 16px;
    width: 16px;
}

.social__button > h3 {
    color: #fff;
    font-size: 13px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 3.9px;
    text-transform: uppercase;
    margin-left: 10px;

    @include sm {
        font-size: 12px;
        letter-spacing: 1px;
        margin-left: 10px;
    }
}

