
.header__container {
    width: 100%;
    height: 10vh;
    position: fixed;
    top: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    z-index: 3;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0);
    backdrop-filter: blur(0px);
    background-color: transparent;
    padding: 7.3px 0px;

    /* Animations */
    transition: all 0.75s ease-in;

    @include md {
        padding: 0px;
        height: 20vh;
        flex-direction: column;
    }

}

.header__animated-container {
    background: rgba(124, 124, 124, 0.5);
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(2px);
}