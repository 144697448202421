
.workModal__container {
    width: 100vw;
    min-height: 100vh;
    background-color: rgba(0,0,0,0.8);
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 6;
    backdrop-filter: blur(5px);
    overflow: hidden;

    &::-webkit-scrollbar {
        width: 0.01px;
        height: 0.01px;
        background-color: transparent;
    }
    
    &::-webkit-scrollbar-thumb {
        background-color: transparent;
        border-radius: 0.01px;
    }
}

.workModal__content-container {
    height: 100%;
    position: relative;
    gap: 4rem;
    row-gap: 2rem;
    grid-template-columns: repeat(3, 1fr);
    overflow: hidden;

    &::-webkit-scrollbar {
        width: 0.01px;
        height: 0.01px;
        background-color: transparent;
    }
    
    &::-webkit-scrollbar-thumb {
        background-color: transparent;
        border-radius: 0.01px;
    }

    width: 70vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    scroll-snap-type: x mandatory;
    padding: 2rem 30rem;


    @include md {
        width: 80vw;
    } 

    
    @include sm {
        width: 80vw;
    } 

    @include xs {
        width: 70vw;
    } 

}

.workModal__info-container {
    width: 100%;
    max-height: 70vh;
    flex-shrink: 0;
    background: #fff;
    overflow: hidden; 
    position: sticky;
    object-fit: cover;
    scroll-snap-align: center;
    transition: all 0.5s;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.30);

}

.workModal__close-image {
    width: 20px;
    height: 20px;
    position: fixed;
    top: 3rem;
    right: 3rem;
    cursor: pointer;
    overflow: hidden;
    z-index: 100;
}

  .workModal__go-arrow {
    margin-top: -10px;
    margin-right: -10px;
    color: white;
    font-size: small;
    overflow: hidden;
  }

.workModal__works-counter {
    position: absolute;
    bottom: 10vh;
    left: 0;
    right: 0;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    & > div {
        width: 10px;
        height: 10px;
        background-color: rgba(255, 255, 255, 0.5);
        margin-left: 7.5px;
        margin-right: 7.5px;
        border-radius: 10rem;
        z-index: 1;
    }

    @include sm {
        bottom: 5vh;
    } 

}
