
.works__container {
    width: 100%;
    min-height: 100vh;
    background: #FFFFFF;
    background-size: cover;
    z-index: 1;
    display: flex;
    align-items: center;
    position: relative;
    padding: 5vh 0vw;

    @include xxl {

        @include sm-h {
            padding: 15vh 0vw;
        }
    }

    @include md {
        min-height: 80vh;
        padding: 0vh 0vw;

        @include sm-h {
            padding: 15vh 0vw;
        }
    }

}

.works__id-target {
    height: 0px;
    width: 0px;
    position: absolute;
    top: 0vh;
    left: 0px;

    @include md {
        top: -15vh;
      }
  
    @include sm {
      top: -20vh;
    }
}

.works__content-container {
    width: 100%;
    min-height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;


}

.works__title-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    position: relative;

    & > hr {
        width: 100%;
        height: 0.1px;
        background-color: #c9c9c9;
        margin: 0rem 4rem;
        border: none;

        @include lg {
            margin: 0rem 3rem;
        }

        @include md {
            margin: 0rem 2rem;
        }

        @include sm {
            margin: 0rem;
            position: absolute;
            visibility: hidden;
        }
    }

    & > h1 {
        min-width: fit-content;
        color: #000;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        letter-spacing: 3.2px;
        text-transform: uppercase;
        font-size: 28px;
        text-align: center;


        @include lg {
            font-size: 22px;
        }
    
        @include md {
            font-size: 16px;
            min-width: 75%;
        }
    
        @include sm {
            font-size: 14px;
            width: 90%;
        }

    }
    
}

.works__list-container {
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    margin-top: 3rem;
    display: flex;
    justify-content: center;

    @include lg {
        display: unset;
        justify-content: none;
    }

    &::-webkit-scrollbar {
        width: 0.01px;
        height: 0.01px;
        background-color: transparent;
    }
    
    &::-webkit-scrollbar-thumb {
        background-color: transparent;
        border-radius: 0.01px;
    }

    
}



.works__list {
    width: fit-content;
    display: grid;
    column-gap: $space;
    margin: 1rem 0rem;
    column-gap: $space;
    padding-top: 10rem;
    padding-bottom: 1rem;

    // animation: scroll 10s ease-in-out infinite;
    

    @include lg {
        column-gap: $space / 2;
    }

    @include md {
        // grid-template-columns: repeat(8, 1fr);
        // animation: scroll 30s ease-in-out infinite;
        // width: auto;
        padding-top: 8rem;
    }

    @include sm {
        column-gap: $space / 3;
        padding-top: 7rem;
    }

    padding-left: 10%;
    padding-right: 10%;
}


  @keyframes scroll {
    0%, 100% {
        transform: translateX(50px);
      }
      50% {
        transform: translateX(-80%);

      }

  }

.works__card {
    height: 50rem;
    width: 30rem;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;
    transition: all 0.4s;
    border: 3px solid #E1E1E1;
    

    &:hover  {
        &::before {
            opacity: 1;

        }
    }

    @include md {
        height: 40rem;
        width: 25rem;
    }

    @include sm {
        height: 30rem;
        width: 17.5rem;
    }
}

.works__card::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5); 
    z-index: 0;
    opacity: 0;
    transition: all 0.4s;

}

  .card-content {
    height: 0%;
    width: 100%;
    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.4s;
    font-size: 0px;
    transform: rotate(45deg) scale(-1);


    & > div {
        // Container
        border: 1px solid #fff;
        padding: 1rem 3rem;
        background-color: transparent;
        cursor: pointer;
        outline: none;
        margin-top: 1rem;
        z-index: 1;

        // Text
        color: #fff;
        text-align: justify;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 3.2px;
        backdrop-filter: blur(10px);

        @include md {
            font-size: 12px;
            border: 1px solid #fff;
            padding: 1rem 2rem;
        }

        @include sm {
            font-size: 10px;
            padding: 1rem 1.5rem;
        }

    }
  }

.works__card:hover .card-content {
    opacity: 1;
    height: 100%;
    font-size: 1.8rem;
    transform: rotate(0deg);
  }

.works__second-card {
    height: 80px;
    width: 100%;
    position: absolute;
    top: 0px;
    z-index: -1;
    border-radius: 10px 10px 0px 0px;
    border: 1px solid #E1E1E1;
    background: #567DB8;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: all 0.4s;

    @include md {
        height: 60px;
    }

    @include sm {
        height: 50px;
    }
  }
  
.works__card:hover .works__second-card {
    transform: translateY(-80px);

    @include md {
        transform: translateY(-60px);
    }

    @include sm {
        transform: translateY(-50px);
    }
  }

.works__second-card > h1 {
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: white;
    text-transform: uppercase;
    padding: 0rem 2rem;
    text-align: center;
    z-index: 1;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 2.8px;
    margin-bottom: 1rem;


    @include md {
        font-size: 14px;
        margin-bottom: 0.7rem;
    }

    @include sm {
        font-size: 12px;
        margin-bottom: 0.4rem;
    }
}

.works__second-card > h2 {
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: white;
    font-style: normal;
    line-height: normal;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 400;
    letter-spacing: 3px;
    padding: 0rem 2rem;
    text-align: center;
    z-index: 1;

    @include sm {
        font-size: 8px;
    }
    
}

.card-content > h2 {
    position: absolute;
    top: 10px;
    color: white;
    font-size: 8px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 5px;
    text-transform: uppercase;
    z-index: 1;
}



.works__card:nth-child(1) {
    background: url('../../images/tejas-servicio.webp') center no-repeat;
    background-size: cover;
}

.works__card:nth-child(2) {
    background: url('../../images/chapas-servicio.webp') center no-repeat;
    background-size: cover;
}

.works__card:nth-child(3) {
    background: url('../../images/pizarras-servicio.webp') center no-repeat;
    background-size: cover;
}

.works__card:nth-child(4) {
    background: url('../../images/madera-servicio.webp') center no-repeat;
    background-size: cover;
}

.works__card:nth-child(5) {
    background: url('../../images/reparaciones-servicio.webp') center no-repeat;
    background-size: cover;
}

.works__card:nth-child(6) {
    background: url('../../images/zinguerias-servicio.webp') center no-repeat;
    background-size: cover;
}

.works__card:nth-child(7) {
    background: url('../../images/industrial-servicio.webp') center no-repeat;
    background-size: cover;
}

.works__card:nth-child(8) {
    background: url('../../images/restaurar-servicio.webp') center no-repeat;
    background-size: cover;
}


// SCROLL ARROW

.works__scroll-container {
    position: absolute;
    right: 0px;
    left: 0px;
    bottom: -5vh;
    height: fit-content;
    width: 50px;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}


.works__scroll-arrow-go-left {
    position: relative;

    // @include sm {
    //     padding-left: 12.5px;
    // }
}

.works__scroll-arrow-go-right {
    position: relative;

    // @include sm {
    //     padding-left: 12.5px;
    // }
}


@keyframes animateGoLeft {
    0% {
        opacity: 0;
        transform: rotate(-225deg) translate(-10px, -10px);
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        transform: rotate(-225deg) translate(10px, 10px);
    }
}

@keyframes animateGoRight {
    0% {
        opacity: 0;
        transform: rotate(-45deg) translate(-10px, -10px);
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        transform: rotate(-45deg) translate(10px, 10px);
    }
}

.works__scroll-arrow-go-left span {
    display: block;
    width: 0.8rem;
    height: 0.8rem;
    border-bottom: 1px solid black;
    border-right: 1px solid black;
    transform: rotate(90deg);
    animation: animateGoLeft 3s infinite;

    @include sm {
        width: 0.5rem;
        height: 0.5rem;
    }
}

.works__scroll-arrow-go-right span {
    display: block;
    width: 0.8rem;
    height: 0.8rem;
    border-bottom: 1px solid black;
    border-right: 1px solid black;
    transform: rotate(90deg);
    animation: animateGoRight 3s infinite;

    @include sm {
        width: 0.5rem;
        height: 0.5rem;
    }
}
